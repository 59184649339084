'use strict'

import '@plugins/contact-form-7/includes/css/styles.css'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../css/default.scss'
import '../css/modules.scss'
import '../css/content.scss'
import '../css/mobile.scss'

//import * as $ from 'jquery'